<template>
  <div class="weChat"></div>
</template>
<script>
// import Login from '@/api/login/index.js'
import { loginBackground, getMatMenuList } from "../../api/login";
import { baseInfo } from "../../api/login/baseInfo";
import { Dictionaries } from "../../api/platformaccount/index";
export default {
  data() {
    return {};
  },
  async created() {
    if (this.$route.query.userInfo) {
      const userInfo =  JSON.parse(unescape(this.$route.query.userInfo))
      //后台跳转
      window.localStorage.setItem(
        "token",
        userInfo.token
      );
      window.localStorage.setItem("user",JSON.stringify( userInfo));
      this.getDictionariesList(); //
      this.background();
      await this.baseInfo(); // 获取机构信息 || 已到期 || 快到期
      await this.getMatMenuList(userInfo.roleId);
    } else {
      this.$router.push({ path: "/login" });
    }
    // alert("判断用户信息");
  },
  methods: {
    async baseInfo() {
      const Res = await baseInfo();
      this.$store.commit('setProdLogo',Res.data.data)
      // window.localStorage.setItem("prod", JSON.stringify(Res.data.data));
    },
    // 请求字典
    getDictionariesList() {
      const todata = (data) =>
        data.map((item) => ({ label: item.label, value: item.value }));
      // 平台类型
      Dictionaries("platform_type").then((Res) => {
        window.localStorage.setItem(
          "platform_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 主体类型
      Dictionaries("body_type").then((Res) => {
        window.localStorage.setItem(
          "body_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 信息类型
      Dictionaries("message_type").then((Res) => {
        window.localStorage.setItem(
          "message_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 认证情况
      Dictionaries("is_authenticate").then((Res) => {
        window.localStorage.setItem(
          "is_authenticate",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 语言种类
      Dictionaries("language_category").then((Res) => {
        window.localStorage.setItem(
          "language_category",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 主办单位性质
      Dictionaries("organizer_type").then((Res) => {
        window.localStorage.setItem(
          "organizer_type",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 前置审批
      Dictionaries("pre_approval").then((Res) => {
        window.localStorage.setItem(
          "pre_approval",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 国家
      Dictionaries("access_country").then((Res) => {
        window.localStorage.setItem(
          "access_country",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 境内外接入
      Dictionaries("access_way").then((Res) => {
        window.localStorage.setItem(
          "access_way",
          JSON.stringify(todata(Res.data.data))
        );
      });
      // 微信公众号-处置状态
      Dictionaries("wechat_dispose_state").then((Res) => {
        window.localStorage.setItem(
          "wechat_dispose_state",
          JSON.stringify(todata(Res.data.data))
        );
      });
    },
    //菜单
    async getMatMenuList(roleId) {
      let res = await getMatMenuList();
      if (res.data.code == 200) {
        if (res.data.data) {
          let data = res.data.data || [];
          // 机构普通用户
          // 机构普通用户
          if (roleId !== 7) {
            // 1000表示是系统设置
            data = data.filter((v) => v.id !== 1000);
          }
          // 1100 大系统研判  1200 我的收藏
          data = data.filter((v) => v.id !== 1100).filter((v) => v.id !== 1200);

          data.forEach((e) => {
            e.isSelect = false;
          });
          data.forEach((e) => {
            e.icon1 = e.icon.split(",")[1];
            e.icon2 = e.icon.split(",")[0];
          });
          window.localStorage.setItem("menu", JSON.stringify(data)); //缓存菜单
          // return
          this.$router.push({
            path: data[0].path, //跳转菜单列表第一个
          });
          window.localStorage.setItem("path", data[0].path); //缓存当前菜单路由
        } else {
          //没有菜单权限
          this.$router.push({ path: "/login" });
          this.$message.warning("您暂无权限访问");
        }
      }
    },
    //获取背景
    async background() {
      // let res  = await Login.loginBackground({requestDomain:btoa('matrix.konne.cn')})
      let res = await loginBackground({
        requestDomain: btoa(window.location.host),
      });
      if (res.data.code == 200) {
        window.localStorage.setItem("loginType", res.data.data.loginMethod); //登录方式
        window.localStorage.setItem("systemName", res.data.data.systemName); //系统名称
        document.title = res.data.data.systemName; //浏览器标题
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
</style>
